import { Clickable } from "./UTILITIES/Clickable";
import { Footer } from "./UTILITIES/Footer";
import { Navigation } from "./UTILITIES/Navigation";
import amLogo from '../IMAGES/amlogo.png';
import ambLogo from '../IMAGES/amblogo.png';
import play from '../IMAGES/play.png';
import apple from '../IMAGES/apple.png';
import '../STYLES/Apps.css'

export default function Apps() {
    return <div className="poppins">
        <Navigation />
        <div className="main full">
            <h1>Ads Mayhem App</h1>
            <img src={amLogo} style={{ width: 100, height: 100, borderRadius: 15 }} />
            <div className="center">
                <div className="text-center">
                    <Clickable onPress={() => { window.open("https://play.google.com/store/apps/details?id=com.iicdev.AdsMahem", "_blank") }}>
                        <img src={play} style={{ width: 200, height: 200 }} />
                    </Clickable>
                    <p>Google Play Store</p>
                </div>
                <div className="text-center">
                    <Clickable onPress={() => { window.open("https://apps.apple.com/us/app/ads-mayhem/id6737462117", "_blank") }}>
                        <img src={apple} style={{ width: 200, height: 200 }} />
                    </Clickable>
                    <p>Apple App Store</p>
                </div>
            </div>
            <br /><br />
            <h1>Ads Mayhem Business App</h1>
            <img src={ambLogo} style={{ width: 100, height: 100, borderRadius: 15 }} />
            <div className="center">
                <div className="text-center">
                    <Clickable onPress={() => { window.open("https://play.google.com/store/apps/details?id=com.iicdev.AdsMahemBusiness", "_blank") }}>
                        <img src={play} style={{ width: 200, height: 200 }} />
                    </Clickable>
                    <p>Google Play Store</p>
                </div>
                <div className="text-center">
                    <Clickable onPress={() => { window.open("https://apps.apple.com/us/app/ads-mayhem-business/id6737673889", "_blank") }}>
                        <img src={apple} style={{ width: 200, height: 200 }} />
                    </Clickable>
                    <p>Apple App Store</p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}